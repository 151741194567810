export default function usePageBreadcrumbs(page: AlchemyPage) {
  return [
    ...page.ancestors
      .filter((ancestor) => {
        return ancestor.urlname !== "homepage"
      })
      .map((ancestor) => ({
        name: ancestor.name,
        url: ancestor.urlPath,
      })),
    { name: page.name, url: page.urlPath },
  ]
}
